const messages = {
	es: {
	  translations: {
		signup: {
		  title: "Regístrate",
		  toasts: {
			success: "Colaborador creado con éxito. ¡Inicia sesión!",
			fail: "Error al crear usuario. Verifica los datos proporcionados.",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Registrarse",
			login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
		  },
		},
		login: {
		  title: "Iniciar sesión",
		  form: {
			email: "Correo electrónico",
			password: "Contraseña",
		  },
		  buttons: {
			submit: "Iniciar sesión",
			register: "¿No tienes una cuenta? ¡Regístrate!",
		  },
		},
		companies: {
		  title: "Registrar Empresa",
		  form: {
			name: "Nombre de la Empresa",
			plan: "Plan",
			token: "Token",
			submit: "Registrar",
			success: "Empresa creada con éxito",
		  },
		},
		auth: {
		  toasts: {
			success: "Inicio de sesión exitoso",
		  },
		  token: "Token",
		},
		dashboard: {
		  charts: {
			perDay: {
			  title: "Atenciones hoy: ",
			},
		  },
		},
		connections: {
		  title: "Conexiones",
		  toasts: {
			deleted: "Conexión con WhatsApp eliminada con éxito",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "¿Estás seguro? Esta acción no se puede deshacer.",
			disconnectTitle: "Desconectar",
			disconnectMessage:
			  "¿Estás seguro? Necesitarás escanear el código QR nuevamente.",
		  },
		  buttons: {
			add: "Agregar WhatsApp",
			disconnect: "Desconectar",
			tryAgain: "Intentar nuevamente",
			qrcode: "CÓDIGO QR",
			newQr: "Nuevo CÓDIGO QR",
			connecting: "Conectando",
		  },
		  toolTips: {
			disconnected: {
			  title: "Error al iniciar sesión de WhatsApp",
			  content:
				"Asegúrate de que tu teléfono esté conectado a internet e inténtalo de nuevo, o solicita un nuevo código QR.",
			},
			qrcode: {
			  title: "Esperando lectura del código QR",
			  content:
				"Haz clic en el botón 'CÓDIGO QR' y escanea el código QR con tu teléfono para iniciar la sesión.",
			},
			connected: {
			  title: "Conexión establecida",
			},
			timeout: {
			  title: "Se perdió la conexión con el teléfono",
			  content:
				"Asegúrate de que tu teléfono esté conectado a internet y WhatsApp esté abierto, o haz clic en el botón 'Desconectar' para obtener un nuevo código QR.",
			},
		  },
		  table: {
			name: "Nombre",
			status: "Estado",
			lastUpdate: "Última actualización",
			default: "Predeterminado",
			actions: "Acciones",
			session: "Sesión",
		  },
		},
		whatsappModal: {
		  title: {
			add: "Agregar WhatsApp",
			edit: "Editar WhatsApp",
		  },
		  form: {
			name: "Nombre",
			default: "Predeterminado",
			sendIdQueue: "Area & Sector",
			timeSendQueue: "Redirigir a la Area & Sector en X minutos",
			queueRedirection: "Redirección de Area & Sector",
			queueRedirectionDesc: "Selecciona una Area & Sector para redirigir a los contactos que no tienen Area & Sector",
  
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "WhatsApp guardado con éxito.",
		},
		qrCode: {
		  message: "Lee el Código QR para iniciar la sesión",
		},
		contacts: {
		  title: "Contactos",
		  toasts: {
			deleted: "Contacto eliminado con éxito",
		  },
		  searchPlaceholder: "Buscar...",
		  confirmationModal: {
			deleteTitle: "Eliminar ",
			importTitlte: "Importar contactos",
			deleteMessage:
			  "¿Estás seguro de que quieres eliminar este contacto? Se perderán todas las conversaciones relacionadas.",
			importMessage: "¿Quieres importar todos los contactos del teléfono?",
		  },
		  buttons: {
			import: "Importar Contactos",
			add: "Agregar Contacto",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "WhatsApp",
			email: "Correo electrónico",
			actions: "Acciones",
		  },
		},
		contactModal: {
		  title: {
			add: "Agregar contacto",
			edit: "Editar contacto",
		  },
		  form: {
			mainInfo: "Datos del contacto",
			extraInfo: "Información adicional",
			name: "Nombre",
			number: "Número de WhatsApp",
			email: "Correo electrónico",
			extraName: "Nombre del campo",
			extraValue: "Valor",
		  },
		  buttons: {
			addExtraInfo: "Agregar información",
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Contacto guardado con éxito.",
		},
		queueModal: {
		  title: {
			add: "Agregar Area & Sector",
			edit: "Editar Area & Sector",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
			greetingMessage: "Mensaje de saludo",
			complationMessage: "Mensaje de conclusión",
			outOfHoursMessage: "Mensaje fuera del horario laboral",
			ratingMessage: "Mensaje de calificación",
			token: "Token",
			orderQueue: "Orden de la Area & Sector (Bot)",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		},
		userModal: {
		  title: {
			add: "Agregar usuario",
			edit: "Editar usuario",
		  },
		  form: {
			name: "Nombre",
			email: "Correo electrónico",
			password: "Contraseña",
			profile: "Perfil",
			whatsapp: "Conexión Predeterminada"
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Colaborador guardado con éxito.",
		},
		scheduleModal: {
		  title: {
			add: "Nuevo Agendamiento",
			edit: "Editar Agendamiento",
		  },
		  form: {
			body: "Mensaje",
			contact: "Contacto",
			sendAt: "Fecha de Agendamiento",
			sentAt: "Fecha de Envío",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Agendamiento guardado con éxito.",
		},
		tagModal: {
		  title: {
			add: "Nueva Etiqueta",
			edit: "Editar Etiqueta",
		  },
		  form: {
			name: "Nombre",
			color: "Color",
		  },
		  buttons: {
			okAdd: "Agregar",
			okEdit: "Guardar",
			cancel: "Cancelar",
		  },
		  success: "Etiqueta guardada con éxito.",
		},
		chat: {
		  noTicketMessage: "Selecciona un ticket para empezar a conversar.",
		},
		uploads: {
		  titles: {
			titleUploadMsgDragDrop: "ARRASTRA Y SUELTA ARCHIVOS EN EL CAMPO ABAJO",
			titleFileList: "Lista de archivo(s)"
		  },
		},
		ticketsManager: {
		  buttons: {
			newTicket: "Nuevo",
		  },
		},
		ticketsQueueSelect: {
		  placeholder: "Area & Sectores",
		},
		tickets: {
		  toasts: {
			deleted: "La atención que tenías fue eliminada.",
		  },
		  notification: {
			message: "Mensaje de",
		  },
		  tabs: {
			open: { title: "Abiertos" },
			closed: { title: "Resueltos" },
			search: { title: "Buscar" },
		  },
		  search: {
			placeholder: "Buscar atención y mensajes",
		  },
		  buttons: {
			showAll: "Todos",
		  },
		},
		transferTicketModal: {
		  title: "Transferir Ticket",
		  fieldLabel: "Escribe para buscar usuarios",
		  fieldQueueLabel: "Transferir a Area & Sector",
		  fieldQueuePlaceholder: "Selecciona una Area & Sector",
		  noOptions: "No se encontraron usuarios con ese nombre",
		  buttons: {
			ok: "Transferir",
			cancel: "Cancelar",
		  },
		},
		ticketsList: {
		  pendingHeader: "En espera",
		  assignedHeader: "Atendiendo",
		  noTicketsTitle: "¡Nada aquí!",
		  noTicketsMessage:
			"No se encontró ninguna atención con ese estado o término de búsqueda",
		  buttons: {
			accept: "Aceptar",
			closed: "Finalizar",
			reopen: "Reabrir"
		  },
		},
		newTicketModal: {
		  title: "Crear Ticket",
		  fieldLabel: "Escribe para buscar el contacto",
		  add: "Agregar",
		  buttons: {
			ok: "Guardar",
			cancel: "Cancelar",
		  },
		},
		mainDrawer: {
		  listItems: {
			dashboard: "Tablero",
			connections: "Conexiones",
			tickets: "Atenciones",
			quickMessages: "Respuestas Rápidas",
			contacts: "Contactos",
			queues: "Area &  Chatbot",
			tags: "Etiquetas",
			administration: "Administración",
			users: "Equipo",
			settings: "Configuraciones",
			helps: "Ayuda",
			messagesAPI: "API",
			schedules: "Agendamientos",
			campaigns: "Campañas",
			annoucements: "Anuncios",
			chats: "Chat en Equipo",
			financeiro: "Facturacion",
			files: "Lista de archivos",
		  },
		  appBar: {
			user: {
			  profile: "Perfil",
			  logout: "Cerrar sesión",
			},
		  },
		},
		files: {
		  title: "Lista de archivos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acción",
		  },
		  toasts: {
			deleted: "Lista eliminada con éxito",
			deletedAll: "Todas las listas fueron eliminadas con éxito",
		  },
		  buttons: {
			add: "Agregar",
			deleteAll: "Eliminar Todos",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteAllTitle: "Eliminar Todos",
			deleteMessage: "¿Estás seguro de que quieres eliminar esta lista?",
			deleteAllMessage: "¿Estás seguro de que quieres eliminar todas las listas?",
		  },
		},
		messagesAPI: {
		  title: "API",
		  textMessage: {
			number: "Número",
			body: "Mensaje",
			token: "Token registrado",
		  },
		  mediaMessage: {
			number: "Número",
			body: "Nombre del archivo",
			media: "Archivo",
			token: "Token registrado",
		  },
		},
		notifications: {
		  noTickets: "Ninguna notificación.",
		},
		quickMessages: {
		  title: "Respuestas Rápidas",
		  searchPlaceholder: "Buscar...",
		  noAttachment: "Sin adjunto",
		  confirmationModal: {
			deleteTitle: "Eliminación",
			deleteMessage: "¡Esta acción es irreversible! ¿Desea continuar?",
		  },
		  buttons: {
			add: "Agregar",
			attach: "Adjuntar Archivo",
			cancel: "Cancelar",
			edit: "Editar",
		  },
		  toasts: {
			success: "Atajo añadido con éxito",
			deleted: "Atajo eliminado con éxito",
		  },
		  dialog: {
			title: "Mensaje Rápido",
			shortcode: "Atajo",
			message: "Respuesta",
			save: "Guardar",
			cancel: "Cancelar",
			geral: "Permitir edición",
			add: "Agregar",
			edit: "Editar",
			visao: "Permitir vista",
		  },
		  table: {
			shortcode: "Atajo",
			message: "Mensaje",
			actions: "Acciones",
			mediaName: "Nombre del Archivo",
			status: "Estado",
		  },
		},
		messageVariablesPicker: {
		  label: "Variables disponibles",
		  vars: {
			contactFirstName: "Primer Nombre",
			contactName: "Nombre",
			greeting: "Saludo",
			protocolNumber: "Protocolo",
			date: "Fecha",
			hour: "Hora",
		  },
		},
		contactLists: {
		  title: "Listas de Contactos",
		  table: {
			name: "Nombre",
			contacts: "Contactos",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Lista",
		  },
		  dialog: {
			name: "Nombre",
			company: "Empresa",
			okEdit: "Editar",
			okAdd: "Agregar",
			add: "Agregar",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede deshacer.",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		contactListItems: {
		  title: "Contactos",
		  searchPlaceholder: "Búsqueda",
		  buttons: {
			add: "Nuevo",
			lists: "Listas",
			import: "Importar",
		  },
		  dialog: {
			name: "Nombre",
			number: "Número",
			whatsapp: "Whatsapp",
			email: "Correo Electrónico",
			okEdit: "Editar",
			okAdd: "Agregar",
			add: "Agregar",
			edit: "Editar",
			cancel: "Cancelar",
		  },
		  table: {
			name: "Nombre",
			number: "Número",
			whatsapp: "Whatsapp",
			email: "Correo Electrónico",
			actions: "Acciones",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede deshacer.",
			importMessage: "¿Desea importar los contactos de esta hoja de cálculo?",
			importTitlte: "Importar",
		  },
		  toasts: {
			deleted: "Registro eliminado",
		  },
		},
		campaigns: {
		  title: "Campañas",
		  searchPlaceholder: "Búsqueda",
		  buttons: {
			add: "Nueva Campaña",
			contactLists: "Listas de Contactos",
		  },
		  table: {
			name: "Nombre",
			whatsapp: "Conexión",
			contactList: "Lista de Contactos",
			status: "Estado",
			scheduledAt: "Programada para",
			completedAt: "Completada",
			confirmation: "Confirmación",
			actions: "Acciones",
		  },
		  dialog: {
			new: "Nueva Campaña",
			update: "Editar Campaña",
			readonly: "Solo Lectura",
			form: {
			  name: "Nombre",
			  message1: "Mensaje 1",
			  message2: "Mensaje 2",
			  message3: "Mensaje 3",
			  message4: "Mensaje 4",
			  message5: "Mensaje 5",
			  confirmationMessage1: "Mensaje de Confirmación 1",
			  confirmationMessage2: "Mensaje de Confirmación 2",
			  confirmationMessage3: "Mensaje de Confirmación 3",
			  confirmationMessage4: "Mensaje de Confirmación 4",
			  confirmationMessage5: "Mensaje de Confirmación 5",
			  messagePlaceholder: "Contenido del mensaje",
			  whatsapp: "Conexión",
			  status: "Estado",
			  scheduledAt: "Programada para",
			  confirmation: "Confirmación",
			  contactList: "Lista de Contacto",
			  tagList: "Lista de Etiquetas",
			  fileList: "Lista de Archivos"
			},
			buttons: {
			  add: "Agregar",
			  edit: "Actualizar",
			  okadd: "Ok",
			  cancel: "Cancelar Disparos",
			  restart: "Reiniciar Disparos",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede deshacer.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			cancel: "Campaña cancelada",
			restart: "Campaña reiniciada",
			deleted: "Registro eliminado",
		  },
		},
		announcements: {
		  title: "Anuncios",
		  searchPlaceholder: "Búsqueda",
		  buttons: {
			add: "Nuevo Anuncio",
			contactLists: "Listas de Anuncios",
		  },
		  table: {
			priority: "Prioridad",
			title: "Título",
			text: "Texto",
			mediaName: "Archivo",
			status: "Estado",
			actions: "Acciones",
		  },
		  dialog: {
			edit: "Edición de Anuncio",
			add: "Nuevo Anuncio",
			update: "Editar Anuncio",
			readonly: "Solo Lectura",
			form: {
			  priority: "Prioridad",
			  title: "Título",
			  text: "Texto",
			  mediaPath: "Archivo",
			  status: "Estado",
			},
			buttons: {
			  add: "Agregar",
			  edit: "Actualizar",
			  okadd: "Ok",
			  cancel: "Cancelar",
			  close: "Cerrar",
			  attach: "Adjuntar Archivo",
			},
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage: "Esta acción no se puede deshacer.",
		  },
		  toasts: {
			success: "Operación realizada con éxito",
			deleted: "Registro eliminado",
		  },
		},
		campaignsConfig: {
		  title: "Configuraciones de Campañas",
		},
		queues: {
		  title: "Area & Chatbot",
		  table: {
			name: "Nombre",
			color: "Color",
			greeting: "Mensaje de bienvenida",
			actions: "Acciones",
			orderQueue: "Orden de la Area & Sector (bot)",
		  },
		  buttons: {
			add: "Agregar Area & Sector",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage:
			  "¿Está seguro? ¡Esta acción no se puede deshacer! Los atendimientos de esta Area & Sector seguirán existiendo, pero ya no tendrán ninguna Area & Sector asignada.",
		  },
		},
		queueSelect: {
		  inputLabel: "Area & Sectores",
		},
		users: {
		  title: "Colaboradores",
		  table: {
			name: "Nombre",
			email: "Correo Electrónico",
			profile: "Perfil",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Agregar usuario",
		  },
		  toasts: {
			deleted: "Colaborador eliminado con éxito.",
		  },
		  confirmationModal: {
			deleteTitle: "Eliminar",
			deleteMessage:
			  "Todos los datos del usuario se perderán. Las atenciones abiertas de este usuario se trasladarán a la Area & Sector.",
		  },
		},
		helps: {
		  title: "Centro de Ayuda",
		},
		schedules: {
		  title: "Agendamientos",
		  confirmationModal: {
			deleteTitle: "¿Está seguro de que desea eliminar esta programación?",
			deleteMessage: "Esta acción no se puede deshacer.",
		  },
		  table: {
			contact: "Contacto",
			body: "Mensaje",
			sendAt: "Fecha de Programación",
			sentAt: "Fecha de Envío",
			status: "Estado",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nuevo Agendamiento",
		  },
		  toasts: {
			deleted: "Agendamiento eliminado con éxito.",
		  },
		},
		tags: {
		  title: "Etiquetas",
		  confirmationModal: {
			deleteTitle: "¿Está seguro de que desea eliminar esta etiqueta?",
			deleteMessage: "Esta acción no se puede deshacer.",
		  },
		  table: {
			name: "Nombre",
			color: "Color",
			tickets: "Registros con Etiqueta",
			actions: "Acciones",
		  },
		  buttons: {
			add: "Nueva Etiqueta",
		  },
		  toasts: {
			deleted: "Etiqueta eliminada con éxito.",
		  },
		},
		settings: {
		  success: "Configuraciones guardadas con éxito.",
		  title: "Configuraciones",
		  settings: {
			userCreation: {
			  name: "Creación de usuario",
			  options: {
				enabled: "Habilitado",
				disabled: "Deshabilitado",
			  },
			},
		  },
		},
		messagesList: {
		  header: {
			assignedTo: "Asignado a:",
			buttons: {
			  return: "Volver",
			  resolve: "Resolver",
			  reopen: "Reabrir",
			  accept: "Aceptar",
			},
		  },
		},
		messagesInput: {
		  placeholderOpen: "Ingrese un mensaje",
		  placeholderClosed:
			"Reabra o acepte este ticket para enviar un mensaje.",
		  signMessage: "Firmar",
		},
		contactDrawer: {
		  header: "Datos del contacto",
		  buttons: {
			edit: "Editar contacto",
		  },
		  extraInfo: "Información adicional",
		},
		fileModal: {
		  title: {
			add: "Agregar lista de archivos",
			edit: "Editar lista de archivos",
		  },
		  buttons: {
			okAdd: "Guardar",
			okEdit: "Editar",
			cancel: "Cancelar",
			fileOptions: "Agregar archivo",
		  },
		  form: {
			name: "Nombre de la lista de archivos",
			message: "Detalles de la lista",
			fileOptions: "Lista de archivos",
			extraName: "Mensaje para enviar con archivo",
			extraValue: "Valor de la opción",
		  },
		  success: "Lista de archivos guardada con éxito",
		},
		ticketOptionsMenu: {
		  schedule: "Programación",
		  delete: "Eliminar",
		  transfer: "Transferir",
		  registerAppointment: "Observaciones del Contacto",
		  appointmentsModal: {
			title: "Observaciones del Contacto",
			textarea: "Observación",
			placeholder: "Inserte aquí la información que desea registrar",
		  },
		  confirmationModal: {
			title: "Eliminar el ticket del contacto",
			message:
			  "¡Atención! Todas las mensajes relacionadas con el ticket se perderán.",
		  },
		  buttons: {
			delete: "Eliminar",
			cancel: "Cancelar",
		  },
		},
		confirmationModal: {
		  buttons: {
			confirm: "Aceptar",
			cancel: "Cancelar",
		  },
		},
		messageOptionsMenu: {
		  delete: "Eliminar",
		  reply: "Responder",
		  confirmationModal: {
			title: "¿Eliminar mensaje?",
			message: "Esta acción no se puede deshacer.",
		  },
		},
		backendErrors: {
		  ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
		  ERR_NO_DEF_WAPP_FOUND:
			"No se encontró ningún WhatsApp predeterminado. Verifique la página de conexiones.",
		  ERR_WAPP_NOT_INITIALIZED:
			"Esta sesión de WhatsApp no ha sido inicializada. Verifique la página de conexiones.",
		  ERR_WAPP_CHECK_CONTACT:
			"No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones",
		  ERR_WAPP_INVALID_CONTACT: "Este no es un número de WhatsApp válido.",
		  ERR_WAPP_DOWNLOAD_MEDIA:
			"No se pudo descargar medios de WhatsApp. Verifique la página de conexiones.",
		  ERR_INVALID_CREDENTIALS:
			"Error de autenticación. Por favor, inténtelo de nuevo.",
		  ERR_SENDING_WAPP_MSG:
			"Error al enviar mensaje de WhatsApp. Verifique la página de conexiones.",
		  ERR_DELETE_WAPP_MSG: "No se pudo eliminar el mensaje de WhatsApp.",
		  ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
		  ERR_SESSION_EXPIRED: "Sesión expirada. Por favor, inicie sesión.",
		  ERR_USER_CREATION_DISABLED:
			"La creación de usuario ha sido deshabilitada por el administrador.",
		  ERR_NO_PERMISSION: "empezando atencion 😊😉",
		  ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
		  ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
		  ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
		  ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
		  ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
		  ERR_NO_WAPP_FOUND: "No se encontró ningún WhatsApp con este ID.",
		  ERR_CREATING_MESSAGE: "Error al crear mensaje en la base de datos.",
		  ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
		  ERR_FETCH_WAPP_MSG:
			"Error al buscar el mensaje en WhatsApp, tal vez sea muy antiguo.",
		  ERR_QUEUE_COLOR_ALREADY_EXISTS:
			"Este color ya está en uso, elija otro.",
		  ERR_WAPP_GREETING_REQUIRED:
			"El mensaje de saludo es obligatorio cuando hay más de una Area & Sector.",
		},
	  },
	},
  };
  
  export { messages };
  
  